import { apiOptions, apiRequest } from "../utils";

export const rolesService = {
  getAll: async ({ params, cancelToken }) => {
    const options = await apiOptions({
      url: "role",
      method: "get",
      cancelToken,
      params,
    });

    return apiRequest(options);
  },

  delete: async (roleId) => {
    const options = await apiOptions({
      url: `role/${roleId}`,
      method: "delete",
    });

    return apiRequest(options);
  },
};
