import React from "react";
import { Box } from "@material-ui/core";
import LoadingButton from "@material-ui/lab/LoadingButton";

const ButtonComponent = ({ text, buttonLoad, onClick }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <LoadingButton
        loading={buttonLoad}
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        onClick={onClick}
      >
        {text}
      </LoadingButton>
    </Box>
  );
};

export default ButtonComponent;
