import { apiOptions, apiRequest } from "../utils";

export const typesService = {
  getAll: async () => {
    const options = await apiOptions({
      url: "itemtype/restaurant",
      method: "get",
    });

    return apiRequest(options);
  },
};
