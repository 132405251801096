import { apiOptions, apiRequest } from "../utils";

export const membershipService = {
  getAll: async ({ params, cancelToken } = {}) => {
    const options = await apiOptions({
      url: "membership",
      method: "get",
      cancelToken,
      params,
      noToken: true,
    });

    return apiRequest(options);
  },

  get: async (id) => {
    const options = await apiOptions({
      url: `membership/${id}`,
      method: "get",
      noToken: true,
    });

    return apiRequest(options);
  },

  getByCustomerGroupId: async (id) => {
    const options = await apiOptions({
      url: `membership/customergroup/${id}`,
      method: "get",
      noToken: true,
    });

    return apiRequest(options);
  },

  replace: async (id, data) => {
    const options = await apiOptions({
      url: `membership/${id}`,
      method: "put",
      data,
    });
    return apiRequest(options);
  },

  create: async (data) => {
    const options = await apiOptions({
      url: "membership",
      method: "post",
      data,
    });
    return apiRequest(options);
  },
};
