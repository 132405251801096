import { apiOptions, apiRequest } from "../utils";

export const revelService = {
  getCustomerGroups: async ({ params, cancelToken } = {}) => {
    const options = await apiOptions({
      url: "revel/customergroups",
      method: "get",
      cancelToken,
      params,
    });

    return apiRequest(options);
  },

  getProducts: async ({ params, cancelToken }) => {
    const options = await apiOptions({
      url: "revel/products",
      method: "get",
      cancelToken,
      params,
    });

    return apiRequest(options);
  },

  getModifiers: async ({ params, cancelToken }) => {
    const options = await apiOptions({
      url: "revel/modifiers",
      method: "get",
      cancelToken,
      params,
    });

    return apiRequest(options);
  },
};
