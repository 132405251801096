import React, { useContext, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Link,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import LocalPizzaIcon from "@material-ui/icons/LocalPizza";
import LunchDiningIcon from "@material-ui/icons/LunchDining";
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu";
import BentoIcon from "@material-ui/icons/Bento";
import GroupIcon from "@material-ui/icons/Group";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import ConnectWithoutContactIcon from "@material-ui/icons/ConnectWithoutContact";
import ShareLocationIcon from "@material-ui/icons/ShareLocation";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import ReceiptLongIcon from "@material-ui/icons/ReceiptLong";
import {
  DocumentIcon,
  ChartPieIcon,
  FolderOpenIcon,
  UserIcon,
} from "../../icons";
import Logo from "../Logo";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import { LoginContext } from "../../App";

const sections = [
  {
    title: "General",
    items: [
      {
        title: "Dashboard",
        path: "/dashboard",
        icon: <ChartPieIcon fontSize="small" />,
      },
      {
        title: "Deliveries",
        path: "/dashboard/deliveries",
        icon: <ShareLocationIcon fontSize="small" />,
      },
      {
        title: "Orders",
        path: "/dashboard/orders",
        icon: <ReceiptLongIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Menu",
    items: [
      {
        title: "Menu",
        path: "/dashboard/menu",
        icon: <FastfoodIcon fontSize="small" />,
      },
      {
        title: "Items",
        path: "/dashboard/item",
        icon: <LocalPizzaIcon fontSize="small" />,
      },
      {
        title: "Ingredient Groups",
        path: "/dashboard/ingredient-group",
        icon: <BentoIcon fontSize="small" />,
      },
      {
        title: "Ingredients",
        path: "/dashboard/ingredient",
        icon: <LunchDiningIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Management",
    items: [
      {
        title: "Restaurant",
        path: "/dashboard/restaurant",
        icon: <RestaurantMenuIcon fontSize="small" />,
      },
      {
        title: "Accounts",
        path: "/dashboard/account",
        icon: <UserIcon fontSize="small" />,
        children: [
          {
            title: "List",
            path: "/dashboard/account",
          },
          {
            title: "Type",
            path: "/dashboard/accounttype",
          },
        ],
      },
      {
        title: "Communities",
        path: "/dashboard/communities",
        icon: <ConnectWithoutContactIcon fontSize="small" />,
      },
      {
        title: "Staff",
        path: "/dashboard/staff",
        icon: <EmojiPeopleIcon fontSize="small" />,
      },
      {
        title: "Users",
        path: "/dashboard/user",
        icon: <GroupIcon fontSize="small" />,
      },
      {
        title: "Delivery Vans",
        path: "/dashboard/delivery-vans",
        icon: <AirportShuttleIcon fontSize="small" />,
      },
      {
        title: "Roles",
        path: "/dashboard/role",
        icon: <FolderOpenIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Legal",
    items: [
      {
        title: "Privacy Policy",
        path: "/dashboard/privacy-policy",
        icon: <MenuBookIcon fontSize="small" />,
      },
      {
        title: "Terms And Conditions",
        path: "/dashboard/terms-and-condition",
        icon: <MenuBookIcon fontSize="small" />,
      },
      {
        title: "Membership Benefits",
        path: "/dashboard/membership-benefits",
        icon: <MenuBookIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Documentation",
    items: [
      {
        title: "Documents",
        path: "/dashboard/documents",
        icon: <DocumentIcon fontSize="small" />,
      },
    ],
  },
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const { userDetail } = useContext(LoginContext);
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              overflow: "hidden",
              p: 2,
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={userDetail.accountTypeBannerImageURL}
                sx={{
                  cursor: "pointer",
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {userDetail.firstName} {userDetail.lastName}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/dashboard/account"
                >
                  {userDetail.accountName}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
