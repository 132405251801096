import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { Formik } from "formik";
import { Box, TextField } from "@material-ui/core";
import { sessionService } from "../../api";
import { LoginContext } from "../../App";
import ButtonComponent from "../widgets/buttons/ButtonComponent";

const LoginJWT = () => {
  const { setUserDetail, setLoginState } = useContext(LoginContext);
  const navigate = useNavigate();
  const [buttonLoad, setButtonLoad] = useState(false);

  const loginAdmin = async (data) => {
    try {
      setButtonLoad(true);
      const response = await sessionService.loginAdmin(data);

      toast.success(response.data.message);
      const UserDetails = response.data.userDetails;
      localStorage.setItem(
        "Login",
        JSON.stringify({
          loginState: true,
          token: UserDetails.jwtToken,
        })
      );
      localStorage.setItem("UserData", JSON.stringify({ UserDetails }));
      setUserDetail(UserDetails);
      setLoginState(true);
      navigate("/dashboard");
    } finally {
      setButtonLoad(false);
    }
  };

  return (
    <Formik
      initialValues={{
        userName: "",
        password: "",
      }}
      validationSchema={Yup.object().shape({
        userName: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={loginAdmin}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <TextField
            autoFocus
            error={Boolean(touched.userName && errors.userName)}
            fullWidth
            helperText={touched.userName && errors.userName}
            label="Email Address"
            autoComplete="off"
            margin="normal"
            name="userName"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.userName}
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
          />
          <ButtonComponent text="Login" buttonLoad={buttonLoad} />
        </Box>
      )}
    </Formik>
  );
};

export default LoginJWT;
