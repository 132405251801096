import { apiOptions, apiRequest } from "../utils";

export const orderStatusesService = {
  getAll: async ({ cancelToken } = {}) => {
    const options = await apiOptions({
      url: "orderstatus",
      method: "get",
      cancelToken,
    });

    return apiRequest(options);
  },
};
