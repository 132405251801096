import { apiOptions, apiRequest } from "../utils";

export const vansService = {
  getAll: async ({ params, filters, cancelToken }) => {
    const options = await apiOptions({
      url: "van",
      method: "get",
      cancelToken,
      params,
      filters,
    });

    return apiRequest(options);
  },

  get: async (vanId) => {
    const options = await apiOptions({
      url: `van/${vanId}`,
      method: "get",
    });

    return apiRequest(options);
  },

  getVanOrders: async (vanId) => {
    const options = await apiOptions({
      url: `van/orders/${vanId}`,
      method: "get",
    });

    return apiRequest(options);
  },

  delete: async (vanId) => {
    const options = await apiOptions({
      url: `van/${vanId}`,
      method: "delete",
    });

    return apiRequest(options);
  },

  update: async (vanId, data) => {
    const options = await apiOptions({
      url: `van/${vanId}`,
      method: "patch",
      data: data,
    });

    return apiRequest(options);
  },

  create: async (data) => {
    const options = await apiOptions({
      url: "van",
      method: "post",
      data: data,
    });

    return apiRequest(options);
  },

  addOrders: async (id, data) => {
    const options = await apiOptions({
      url: `van/${id}/orders`,
      method: "post",
      data: data,
    });

    return apiRequest(options);
  },
};
