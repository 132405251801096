import { useEffect, useState } from "react";
import { HTTP_STATUSES } from "../constants";
import { useDebounce } from "./useDebounce";

export const usePagination = ({ getData, defaultPageSize = 10 }) => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [totalCount, setTotalCount] = useState(0);
  const [filters, setFilters] = useState();
  const [sort, setSort] = useState();

  const debouncedQuery = useDebounce(search, 300);
  const debouncedFilters = useDebounce(filters, 300);

  useEffect(() => {
    getPageData();
  }, [page, pageSize, sort, debouncedQuery, debouncedFilters]);

  const getPageData = async () => {
    const response = await getData({
      params: {
        pageSize: pageSize,
        pageOffset: page,
        search: search,
        includeTotal: true,
      },
      filters,
      sort,
    });
    if (response.status === HTTP_STATUSES.OK) {
      setData(response.data);
      setTotalCount(+response.headers.totalcount);
    }
  };

  const handleSearchChange = ({ target: { value } }) => {
    setSearch(value);
  };

  const handleFilterChange = (filter) => {
    setFilters(filter);
    setPage(0);
  };

  const handleSortChange = (sort) => {
    setSort(sort);
    setPage(0);
  };

  return {
    data,
    page,
    pageSize,
    totalCount,
    search,
    filters,
    sort,
    getPageData,
    handlePageSizeChange: setPageSize,
    handlePageChange: setPage,
    handleSortChange,
    handleSearchChange,
    handleFilterChange,
  };
};
