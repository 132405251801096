import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slick_slider.css";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Box } from "@material-ui/core";

const SlickSlider = ({ items }) => {
  const settings = {
    dots: false,
    infinite: items.length > 5,
    slidesToShow: 8,
    slidesToScroll: 2,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          height: 200,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          height: 200,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          cssEase: "linear",
        },
      },
    ],
    cssEase: "linear",
  };

  return (
    <Box>
      <Typography variant="h6">Daily Trending Items</Typography>
      <Slider {...settings}>
        {items
          .filter((item) => item.active)
          .map((item) => (
            <Box
              key={item.id}
              sx={{ width: "100%", height: "100%" }}
              component={RouterLink}
              to={`/dashboard/item/${item.id}/edit`}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  padding: "10px",
                  background: "#fff",
                  borderRadius: "10px",
                }}
                src={item.imageUrl}
              />
            </Box>
          ))}
      </Slider>
    </Box>
  );
};

export default SlickSlider;
