import React from "react";
import createSvgIcon from "@material-ui/core/utils/createSvgIcon";

const DocumentText = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"
    ></path>
  </svg>,
  "file"
);

export default DocumentText;
