import { apiOptions, apiRequest } from "../utils";

export const communitiesService = {
  getAll: async ({ params, cancelToken, filters, sort } = {}) => {
    const options = await apiOptions({
      url: "communities",
      method: "get",
      cancelToken,
      params,
      filters,
      sort,
    });

    return apiRequest(options);
  },

  get: async (communityId) => {
    const options = await apiOptions({
      url: `communities/${communityId}`,
      method: "get",
    });

    return apiRequest(options);
  },

  delete: async (communityId) => {
    const options = await apiOptions({
      url: `communities/${communityId}`,
      method: "delete",
    });

    return apiRequest(options);
  },

  create: async (data) => {
    const options = await apiOptions({
      url: "communities",
      method: "post",
      data,
    });
    return apiRequest(options);
  },

  update: async (communityId, data) => {
    const options = await apiOptions({
      url: `communities/${communityId}`,
      method: "patch",
      data,
    });
    return apiRequest(options);
  },
};
