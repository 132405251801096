import { apiOptions, apiRequest } from "../utils";

export const ingredientsService = {
  getAll: async ({ params, filters, cancelToken, sort }) => {
    const options = await apiOptions({
      url: "ingredient",
      method: "get",
      cancelToken,
      params,
      filters,
      sort,
    });

    return apiRequest(options);
  },

  get: async (ingredientId) => {
    const options = await apiOptions({
      url: `ingredient/${ingredientId}`,
      method: "get",
    });

    return apiRequest(options);
  },

  delete: async (ingredientId) => {
    const options = await apiOptions({
      url: `ingredient/${ingredientId}`,
      method: "delete",
    });

    return apiRequest(options);
  },

  create: async (data) => {
    const options = await apiOptions({
      url: "ingredient",
      method: "post",
      data: data,
    });

    return apiRequest(options);
  },

  update: async (ingredientId, data) => {
    const options = await apiOptions({
      url: `ingredient/${ingredientId}`,
      method: "patch",
      data: data,
    });

    return apiRequest(options);
  },
};
