import { keyframes } from "@emotion/react";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { itemsService, ordersService } from "../../api";
import { HTTP_STATUSES } from "../../constants";
import { ArrowRightIcon } from "../../icons";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import { createAndFilter, setRangeOperation, toText } from "../../utils";
import SlickSlider from "./SlickSlider";
import { startOfDay, endOfDay } from "date-fns";

const DashboardPage = () => {
  const sideImg = "/static/pizzaDash.png";
  const currentDate = new Date();
  const [items, setItems] = useState([]);
  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState(null);

  useEffect(() => {
    getItems();
    getOrders();
    getTotalOrders();
  }, []);

  const getItems = async () => {
    const response = await itemsService.getAll({
      params: {
        pageSize: 100,
      },
    });
    if (response.status === HTTP_STATUSES.OK) {
      setItems(response.data);
    }
  };

  const getOrders = async () => {
    const response = await ordersService.getAll({
      params: {
        pageSize: 5,
        includeTotal: true,
      },
    });
    if (response.status === HTTP_STATUSES.OK) {
      setOrders(response.data);
    }
  };

  const getTotalOrders = async () => {
    let filters = createAndFilter();
    filters = setRangeOperation(
      { ...filters },
      {
        scheduledOn: [
          startOfDay(currentDate).toISOString(),
          endOfDay(currentDate).toISOString(),
        ],
      }
    );

    const response = await ordersService.getAll({
      params: {
        pageSize: 1,
        includeTotal: true,
      },
      filters,
    });
    if (response.status === HTTP_STATUSES.OK) {
      setTotalOrders(parseInt(response.headers.totalcount));
    }
  };

  const spin = keyframes`
  from {
    transform:rotate(358deg);
  }
  to {
    transform:rotate(359deg);
  }
  `;

  return (
    <Box sx={{ minHeight: "100%", py: 8, px: "20px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Box
            component={Paper}
            sx={{
              bgcolor: "#e8deeb",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              position: "relative",
              zIndex: 0,
              overflow: "hidden",
              padding: "15px 30px",
              width: "100%",
              height: "200px",
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: { lg: ":300px", md: "300px", sm: "200px", xs: "150px" },
                height: { lg: "200px", md: "200px", sm: "150px", xs: "100px" },
                zIndex: "-1",
              }}
            >
              <img width="100%" height="100%" src={sideImg} />
            </Box>
            <Box
              sx={{ display: "block", marginRight: "auto", marginLeft: "15%" }}
            >
              <Typography variant="h4">Welcome to</Typography>
              <Typography variant="h3">Luis' Prime</Typography>
              <Button
                sx={{ marginTop: "15px" }}
                variant="contained"
                component={RouterLink}
                to={"/dashboard/menu"}
              >
                <FastfoodIcon /> &nbsp; Menu
              </Button>
            </Box>
          </Box>
        </Grid>
        {orders.length > 0 && (
          <Grid item xs={12} sm={12} md={12}>
            <Box
              component={Paper}
              sx={{
                borderRadius: "10px",
                marginTop: "20px",
                overflow: "hidden",
              }}
            >
              <Box sx={{ background: "#e8deeb", padding: "10px 20px" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Recent Orders
                </Typography>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ minWidth: 60 }}>#</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell>Community</TableCell>
                      <TableCell>Scheduled</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map((order) => (
                      <TableRow key={order.orderId}>
                        <TableCell sx={{ padding: "2px" }}>
                          <Button
                            component={RouterLink}
                            to={`/dashboard/orders/${order.orderId}`}
                          >
                            {order.orderId}
                          </Button>
                        </TableCell>
                        <TableCell sx={{ width: "200px" }}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box
                              sx={{
                                width: "42px",
                                height: "42px",
                                marginRight: "3px",
                                position: "reative",
                                border: "2px solid #763f8c",
                                borderRadius: "50%",
                                overflow: "hidden",
                              }}
                            >
                              <Box
                                sx={{
                                  background: "#f1f1f1",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "20px",
                                    lineHeight: "1.9",
                                    textAlign: "center",
                                  }}
                                >
                                  {order.userName.charAt(0)}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                diplay: "flex",
                                alignItem: "center",
                                flexDirection: "column",
                                alignItem: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  fontWeight: "bold",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {order.userName}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  fontWeight: "100",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {order.userEmail}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {`${order.community} (${order.city})`}
                        </TableCell>
                        <TableCell>
                          {new Date(order.scheduledOn).toLocaleDateString(
                            "en-us"
                          )}
                        </TableCell>
                        <TableCell>
                          {order.orderStatus === "Completed" ? (
                            <Chip
                              sx={{ background: "rgb(7 255 48 / 8%)" }}
                              label={order.orderStatus}
                            />
                          ) : (
                            <>
                              {order.orderStatus === "Pending" ? (
                                <Chip
                                  sx={{ background: "rgb(247 90 90 / 8%)" }}
                                  label={order.orderStatus}
                                />
                              ) : (
                                <Chip label={toText(order.orderStatus)} />
                              )}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                sx={{
                  padding: "10px 20px",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1">
                  {`Today's Order Total: ${totalOrders}`}
                </Typography>
                <Button
                  to="/dashboard/orders"
                  component={RouterLink}
                  variant="contained"
                  sx={{ marginLeft: 10 }}
                >
                  See More
                  <IconButton
                    sx={{
                      color: "#fff",
                      padding: "0px",
                      marginLeft: "10px",
                      animation: `${spin} 1.5s infinite linear alternate`,
                    }}
                  >
                    <ArrowRightIcon sx={{ fontSize: "15px" }} />
                  </IconButton>
                </Button>
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12}>
          <Box
            sx={{
              marginTop: "10px",
              width: "100%",
              background: "#e7e9ed",
              display: "block",
              height: "auto",
              padding: "20px",
              borderRadius: "15px",
            }}
          >
            <SlickSlider items={items} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardPage;
