import { FILTER } from "../constants";
import { isValid, startOfDay, endOfDay } from "date-fns";
import { toUTC, toISO } from "./date";

const toQueryStrings = (values, operatorValue) =>
  values
    .map((value) => {
      const date = toUTC(new Date(value));
      if (!isValid(date)) {
        return null;
      }

      switch (operatorValue) {
        case FILTER.ON_OPERATOR:
          return `${toISO(startOfDay(date))}~${toISO(endOfDay(date))}`;
        case FILTER.ON_OR_AFTER_OPERATOR:
          return `${toISO(startOfDay(date))}~`;
        case FILTER.ON_OR_BEFORE_OPERATOR:
          return `~${toISO(endOfDay(date))}`;
        default:
          return null;
      }
    })
    .filter((e) => !!e)
    .join(FILTER.OR_SEPARATOR);

export const createOredFields = (fieldValuePairs) => {
  return fieldValuePairs
    .map((e) => `${e.columnField}${FILTER.ASSIGNMENT_SEPARATOR}${e.value}`)
    .join(FILTER.OR_SEPARATOR);
};

export const createAndedFields = (fieldValuesPair) => {
  return fieldValuesPair
    .map((e) => {
      if (Array.isArray(e.value)) {
        return `${e.columnField}${FILTER.ASSIGNMENT_SEPARATOR}${e.value.join(
          FILTER.OR_SEPARATOR
        )}`;
      }
      return `${e.columnField}${FILTER.ASSIGNMENT_SEPARATOR}${e.value}`;
    })
    .join(FILTER.AND_SEPARATOR);
};

export const createQueryString = (filters) => {
  const separator = selectSeparator(filters.linkOperator);
  if (!separator) {
    return null;
  }
  const fieldStrings = filters.items
    .map((e) => {
      const valueString = wrapValues(e);
      if (!valueString) {
        return null;
      }
      return `${e.columnField}${FILTER.ASSIGNMENT_SEPARATOR}${valueString}`;
    })
    .filter((e) => !!e);
  return fieldStrings.join(separator);
};

const wrapValues = (fieldObj) => {
  if (fieldObj.value === undefined) {
    return null;
  }
  const value = Array.isArray(fieldObj.value)
    ? fieldObj.value
    : [fieldObj.value];

  switch (fieldObj.operatorValue) {
    case FILTER.EQUALS_OPERATOR:
    case FILTER.IS_OPERATOR:
    case FILTER.IS_ANY_OF:
      return value.filter((e) => !!e).join(FILTER.OR_SEPARATOR);
    case FILTER.RANGE_OPERATOR:
      return value.map((e) => `${e[0]}~${e[1]}`).join(FILTER.OR_SEPARATOR);
    case FILTER.STARTS_WITH_OPERATOR:
      return value.map((e) => `${e}*`).join(FILTER.OR_SEPARATOR);
    case FILTER.ENDS_WITH_OPERATOR:
      return value.map((e) => `*${e}`).join(FILTER.OR_SEPARATOR);
    case FILTER.CONTAINS_OPERATOR:
      return value.map((e) => `*${e}*`).join(FILTER.OR_SEPARATOR);
    case FILTER.ON_OPERATOR:
    case FILTER.ON_OR_AFTER_OPERATOR:
    case FILTER.ON_OR_BEFORE_OPERATOR:
      return toQueryStrings(value, fieldObj.operatorValue);
    default:
      return null;
  }
};

const selectSeparator = (selection) => {
  switch (selection) {
    case FILTER.AND_FILTER:
      return FILTER.AND_SEPARATOR;
    case FILTER.OR_FILTER:
      return FILTER.OR_SEPARATOR;
    default:
      return null;
  }
};

export const createAndFilter = () => {
  return { linkOperator: FILTER.AND_FILTER, items: [] };
};

export const createOrFilter = () => {
  return { linkOperator: FILTER.OR_FILTER, items: [] };
};

export const setRangeOperation = (filters, fieldValuePairs, id) => {
  const entries = Object.entries(fieldValuePairs)
    .filter(([, value]) => Array.isArray(value))
    .map(([field, value]) => {
      if (Array.isArray(value[0])) {
        return {
          id,
          operatorValue: FILTER.RANGE_OPERATOR,
          columnField: field,
          value: value,
        };
      }
      return {
        id,
        operatorValue: FILTER.RANGE_OPERATOR,
        columnField: field,
        value: [value],
      };
    });
  const fieldNames = entries.map((e) => e.columnField);
  filters.items = [
    ...filters.items.filter(
      (e) => !fieldNames.some((f) => f === e.columnField)
    ),
    ...entries,
  ];
  return filters;
};

export const addRangeOperation = (filters, fieldValuePairs, id) => {
  const entries = Object.entries(fieldValuePairs)
    .filter(([, value]) => Array.isArray(value))
    .map(([field, value]) => {
      if (Array.isArray(value[0])) {
        return {
          id,
          operatorValue: FILTER.RANGE_OPERATOR,
          columnField: field,
          value: value,
        };
      }
      return {
        id,
        operatorValue: FILTER.RANGE_OPERATOR,
        columnField: field,
        value: [value],
      };
    });
  filters.items = [...filters.items, ...entries];
  return filters;
};

export const setLessThanEqualOperation = (filters, fieldValuePairs, id) => {
  const mappedEntries = Object.entries(fieldValuePairs).map(
    ([field, value]) => {
      return { [field]: [null, value] };
    }
  );
  const args = Object.assign({}, ...mappedEntries);
  return setRangeOperation(filters, args, id);
};

export const addLessThanEqualOperation = (filters, fieldValuePairs, id) => {
  const mappedEntries = Object.entries(fieldValuePairs).map(
    ([field, value]) => {
      return { [field]: [null, value] };
    }
  );
  const args = Object.assign({}, ...mappedEntries);
  return addRangeOperation(filters, args, id);
};

export const setGreaterThanEqualOperation = (filters, fieldValuePairs, id) => {
  const mappedEntries = Object.entries(fieldValuePairs).map(
    ([field, value]) => {
      return { [field]: [value, null] };
    }
  );
  const args = Object.assign({}, ...mappedEntries);
  return setRangeOperation(filters, args, id);
};

export const addGreaterThanEqualOperation = (filters, fieldValuePairs, id) => {
  const mappedEntries = Object.entries(fieldValuePairs).map(
    ([field, value]) => {
      return { [field]: [value, null] };
    }
  );
  const args = Object.assign({}, ...mappedEntries);
  return addRangeOperation(filters, args, id);
};

export const setEqualsOperation = (filters, fieldValuePairs, id) => {
  return setOperation(filters, fieldValuePairs, FILTER.EQUALS_OPERATOR, id);
};

export const addEqualsOperation = (filters, fieldValuePairs, id) => {
  return addOperation(filters, fieldValuePairs, FILTER.EQUALS_OPERATOR, id);
};

export const setStartsWithOperation = (filters, fieldValuePairs, id) => {
  return setOperation(
    filters,
    fieldValuePairs,
    FILTER.STARTS_WITH_OPERATOR,
    id
  );
};

export const addStartsWithOperation = (filters, fieldValuePairs, id) => {
  return addOperation(
    filters,
    fieldValuePairs,
    FILTER.STARTS_WITH_OPERATOR,
    id
  );
};

export const setEndsWithOperation = (filters, fieldValuePairs, id) => {
  return setOperation(filters, fieldValuePairs, FILTER.ENDS_WITH_OPERATOR, id);
};

export const addEndsWithOperation = (filters, fieldValuePairs, id) => {
  return addOperation(filters, fieldValuePairs, FILTER.ENDS_WITH_OPERATOR, id);
};

export const setContainsOperation = (filters, fieldValuePairs, id) => {
  return setOperation(filters, fieldValuePairs, FILTER.CONTAINS_OPERATOR, id);
};

export const addContainsOperation = (filters, fieldValuePairs, id) => {
  return addOperation(filters, fieldValuePairs, FILTER.CONTAINS_OPERATOR, id);
};

const setOperation = (filters, fieldValuePairs, operatorValue, id) => {
  const entries = Object.entries(fieldValuePairs)
    .filter(([, value]) => value !== undefined)
    .map(([field, value]) => {
      if (Array.isArray(value)) {
        return { id, operatorValue, columnField: field, value: value };
      }
      return { id, operatorValue, columnField: field, value: [value] };
    });
  const fieldNames = entries.map((e) => e.columnField);
  if (filters.items) {
    filters.items = [
      ...filters.items.filter(
        (e) => !fieldNames.some((f) => f === e.columnField)
      ),
      ...entries,
    ];
  } else {
    filters.items = [...entries];
  }
  return filters;
};

const addOperation = (filters, fieldValuePairs, operatorValue, id) => {
  const entries = Object.entries(fieldValuePairs)
    .filter(([, value]) => value !== undefined)
    .map(([field, value]) => {
      if (Array.isArray(value)) {
        return { id, operatorValue, columnField: field, value: value };
      }
      return { id, operatorValue, columnField: field, value: [value] };
    });
  filters.items = [...filters.items, ...entries];
  return filters;
};

export const removeOperationByField = (filters, field) => {
  filters.items = filters.items.filter((e) => e.columnField !== field);
  return filters;
};

export const removeOperationById = (filters, id) => {
  filters.items = filters.items.filter((e) => e.id !== id);
  return filters;
};

export const combineFilters = (filter1, filter2) => {
  if (!filter1) {
    return filter2;
  }
  if (!filter2) {
    return filter1;
  }

  const items = [...filter1.items, ...filter2.items];
  return { ...filter1, ...filter2, items };
};
