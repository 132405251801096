import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import {
  Alert,
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  Popover,
  Typography,
} from "@material-ui/core";
import { useAuth } from "../../hooks";
import { LoginContext } from "../../App";
import { BASE_URL } from "../../baseUrl";

const AccountPopover = () => {
  const anchorRef = useRef(null);
  const { logout } = useAuth();
  const { userDetail } = useContext(LoginContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      axios
        .post(`${BASE_URL}session/logout`)
        .then((response) => {
          if (response.data.message === "Success") {
            localStorage.removeItem("Login");
            localStorage.removeItem("UserData");
            toast.success("Logged out successfully");
            setTimeout(() => {
              navigate("/");
            }, 1000);
          }
        })
        .catch((error) => {
          if (error) {
            toast.error("Logout Failed");
          }
        });
    } catch (err) {
      toast.error(err || "Unable to logout.");
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      {userDetail.plannedMaintenanceMessage && (
        <Alert
          severity="error"
          style={{ marginRight: 50, wordWrap: "break-word" }}
        >
          {userDetail.plannedMaintenanceMessage}
        </Alert>
      )}
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <Avatar
          src={userDetail.accountTypeBannerImageURL}
          sx={{
            height: 32,
            width: 32,
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {userDetail.firstName || ""} {userDetail.lastName || ""}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            {userDetail.accountName || ""}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};

export default AccountPopover;
