import { apiOptions, apiRequest } from "../utils";

export const staffsService = {
  getAll: async ({ params, filters, cancelToken }) => {
    const options = await apiOptions({
      url: "staff",
      method: "get",
      cancelToken,
      params,
      filters,
    });

    return apiRequest(options);
  },

  get: async (staffId) => {
    const options = await apiOptions({
      url: `staff/${staffId}`,
      method: "get",
    });

    return apiRequest(options);
  },

  delete: async (staffId) => {
    const options = await apiOptions({
      url: `staff/${staffId}`,
      method: "delete",
    });

    return apiRequest(options);
  },

  update: async (staffId, data) => {
    const options = await apiOptions({
      url: `staff/${staffId}`,
      method: "put",
      data,
    });

    return apiRequest(options);
  },

  create: async (data) => {
    const options = await apiOptions({
      url: "staff",
      method: "post",
      data,
    });

    return apiRequest(options);
  },
};
