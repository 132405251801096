import axios from "axios";
import toast from "react-hot-toast";

const axiosInstance = axios.create({
  timeout: 30000,
});

const reportSuccess = (toastMessage) => {
  if (toastMessage?.message) {
    toast.success(toastMessage.message);
  }
};

const reportError = (toastMessage, error) => {
  const errorObj = {
    message: error?.response?.data?.errorBody,
    error: true,
    code: error?.code,
    status: error?.response?.status,
    statusCode: error?.response?.status,
    errorBody:
      error?.response?.data?.title ||
      error?.response?.data ||
      "An error occured while updating or retrieving data",
  };
  if (toastMessage?.message) {
    toast.error(toastMessage?.message);
  } else {
    toast.error(errorObj.errorBody?.message || "Something went wrong");
  }
  return errorObj;
};

const handleError = (error, toastMessage = null) => {
  return reportError(toastMessage, error);
};

export const apiRequest = async (request, toastMessage = null) => {
  try {
    const result = await axiosInstance(request);
    if (result?.status < 400) {
      reportSuccess(toastMessage?.success);
      return result;
    }
    throw result;
  } catch (error) {
    if (axios.isCancel(error)) {
      return {
        canceled: true,
        request,
        message: "This request was cancelled",
      };
    }
    return handleError(error, toastMessage);
  }
};

export const setupInterceptor = (navigate) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        localStorage.removeItem("Login");
        navigate("/dashboard");
        return;
      }
      return error;
    }
  );
};
