import React, { useEffect, createContext, useState } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import "./i18n";
import RTL from "./components/RTL";
import SplashScreen from "./components/SplashScreen";
import { gtmConfig } from "./config";
import { useAuth, useScrollReset, useSettings } from "./hooks";
import gtm from "./lib/gtm";
import routes from "./routes";
import { createCustomTheme } from "./theme";
import { setupInterceptor } from "./utils";

export const LoginContext = createContext({});
const App = () => {
  const [userDetail, setUserDetail] = useState("");
  const [loginState, setLoginState] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setupInterceptor(navigate);
    const storageData = JSON.parse(localStorage.getItem("UserData"));
    if (storageData) {
      setUserDetail(storageData.UserDetails);
    } else {
      setUserDetail("");
    }
  }, []);

  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();

  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <LoginContext.Provider
      value={{
        userDetail,
        setUserDetail,
        loginState,
        setLoginState,
        loginState,
      }}
    >
      <ThemeProvider theme={theme}>
        <RTL direction={settings.direction}>
          <CssBaseline />
          <Toaster position="top-center" />
          {auth.isInitialized ? content : <SplashScreen />}
        </RTL>
      </ThemeProvider>
    </LoginContext.Provider>
  );
};

export default App;
