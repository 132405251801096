import { apiOptions, apiRequest } from "../utils";

export const itemsService = {
  getAll: async ({ params, filters, sort, cancelToken }) => {
    const options = await apiOptions({
      url: "item",
      method: "get",
      cancelToken,
      params,
      filters,
      sort,
    });

    return apiRequest(options);
  },

  get: async (itemId) => {
    const options = await apiOptions({
      url: `item/${itemId}`,
      method: "get",
    });

    return apiRequest(options);
  },

  delete: async (itemId) => {
    const options = await apiOptions({
      url: `item/${itemId}`,
      method: "delete",
    });

    return apiRequest(options);
  },

  create: async (data) => {
    const options = await apiOptions({
      url: "item",
      method: "post",
      data,
    });

    return apiRequest(options);
  },

  update: async (itemId, data) => {
    const options = await apiOptions({
      url: `item/${itemId}`,
      method: "patch",
      data,
    });

    return apiRequest(options);
  },

  addItemIngredientGroup: async (itemId, data) => {
    const options = await apiOptions({
      url: `item/${itemId}/groups`,
      method: "post",
      data,
    });
    return apiRequest(options);
  },

  replaceItemIngredientGroup: async (itemId, groupId, data) => {
    const options = await apiOptions({
      url: `item/${itemId}/groups/${groupId}`,
      method: "put",
      data,
    });
    return apiRequest(options);
  },

  getItemIngredients: async (itemId, groupId) => {
    const options = await apiOptions({
      url: `item/${itemId}/groups/${groupId}`,
      method: "get",
    });
    return apiRequest(options);
  },

  replaceItemGroupDefaultItems: async (itemId, groupId, data) => {
    const options = await apiOptions({
      url: `item/${itemId}/groups/${groupId}/defaultingredients`,
      method: "put",
      data,
    });

    return apiRequest(options);
  },

  deleteItemIngredientGroup: async (itemId, groupId) => {
    const options = await apiOptions({
      url: `item/${itemId}/groups/${groupId}`,
      method: "delete",
    });
    return apiRequest(options);
  },
};
