import { apiOptions, apiRequest } from "../utils";

export const restaurantsService = {
  getAll: async ({ params, filters, cancelToken }) => {
    const options = await apiOptions({
      url: "restaurant",
      method: "get",
      cancelToken,
      params,
      filters,
    });

    return apiRequest(options);
  },

  update: async (restaurantId, data) => {
    const options = await apiOptions({
      url: `restaurant/${restaurantId}`,
      method: "patch",
      data: data,
    });

    return apiRequest(options);
  },

  create: async (data) => {
    const options = await apiOptions({
      url: "restaurant",
      method: "post",
      data: data,
    });

    return apiRequest(options);
  },

  delete: async (restaurantId) => {
    const options = await apiOptions({
      url: `restaurant/${restaurantId}`,
      method: "delete",
    });

    return apiRequest(options);
  },

  get: async (restaurantId) => {
    const options = await apiOptions({
      url: `restaurant/${restaurantId}`,
      method: "get",
    });

    return apiRequest(options);
  },
};
