import { apiOptions, apiRequest } from "../utils";

export const deliveriesService = {
  getAll: async ({ params, filters, cancelToken } = {}) => {
    const options = await apiOptions({
      url: "delivery",
      method: "get",
      cancelToken,
      params,
      filters,
    });

    return apiRequest(options);
  },

  getStatistics: async (date) => {
    const options = await apiOptions({
      url: `delivery/statistics?deliveryDate=${date}`,
      method: "get",
    });

    return apiRequest(options);
  },

  delete: async (deliveryId) => {
    const options = await apiOptions({
      url: `delivery/${deliveryId}`,
      method: "delete",
    });

    return apiRequest(options);
  },

  getCommunityDeliveries: async (date) => {
    const options = await apiOptions({
      url: `delivery/communitydelivery?deliveryDate=${date}`,
      method: "get",
    });

    return apiRequest(options);
  },
};
