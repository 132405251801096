import { apiOptions, apiRequest } from "../utils";

export const usersService = {
  getAll: async ({ params, filters, sort, cancelToken }) => {
    const options = await apiOptions({
      url: "user",
      method: "get",
      cancelToken,
      params,
      filters,
      sort,
    });

    return apiRequest(options);
  },

  get: async (userId) => {
    const options = await apiOptions({
      url: `user/${userId}`,
      method: "get",
    });

    return apiRequest(options);
  },

  resendEmail: async (email) => {
    const options = await apiOptions({
      url: `user/resendverificationmail?email=${email}`,
      method: "post",
    });

    return apiRequest(options);
  },

  resendPhone: async (email, phoneNumber) => {
    const options = await apiOptions({
      url: `user/resendphoneverificationcode?email=${email}&phoneNumber=${phoneNumber}`,
      method: "post",
    });

    return apiRequest(options);
  },

  create: async (data) => {
    const options = await apiOptions({
      url: "user",
      method: "post",
      data: data,
    });
    return apiRequest(options);
  },

  update: async (userId, data) => {
    const options = await apiOptions({
      url: `user/${userId}`,
      method: "patch",
      data: data,
    });
    return apiRequest(options);
  },

  delete: async (userId) => {
    const options = await apiOptions({
      url: `user/${userId}`,
      method: "delete",
    });
    return apiRequest(options);
  },
};
