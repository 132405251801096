import { apiOptions, apiRequest } from "../utils";

export const filesService = {
  upload: async (base64) => {
    const options = await apiOptions({
      url: "files",
      method: "post",
      data: { base64Attachment: base64 },
    });

    return apiRequest(options);
  },

  delete: async (id) => {
    const options = await apiOptions({
      url: `files/metadata/${id}`,
      method: "delete",
    });

    return apiRequest(options);
  },

  create: async (data) => {
    const options = await apiOptions({
      url: "files/metadata",
      method: "post",
      data,
    });
    return apiRequest(options);
  },

  update: async (id, data) => {
    const options = await apiOptions({
      url: `files/metadata/${id}`,
      method: "patch",
      data,
    });
    return apiRequest(options);
  },
  convertToBase64Promise: (data) => {
    return new Promise((resolve, reject) => {
      try {
        const fileReader = new FileReader();
        fileReader.onloadend = () => resolve(fileReader.result);
        fileReader.onerror = (error) => reject(error);
        fileReader.readAsDataURL(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  convertToBase64: (data, callback) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => callback(fileReader.result, data);
    fileReader.readAsDataURL(data);
  },
};
