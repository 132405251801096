import { apiOptions, apiRequest } from "../utils";

export const ordersService = {
  getAll: async ({ params, filters, cancelToken, sort } = {}) => {
    const options = await apiOptions({
      url: "orders",
      method: "get",
      cancelToken,
      params,
      filters,
      sort,
    });

    return apiRequest(options);
  },

  get: async (orderId) => {
    const options = await apiOptions({
      url: `orders/orderdetail/${orderId}`,
      method: "get",
    });

    return apiRequest(options);
  },

  update: async (orderId, data) => {
    const options = await apiOptions({
      url: `orders/${orderId}`,
      method: "patch",
      data,
    });

    return apiRequest(options);
  },
};
