import {
  getGridDateOperators,
  GridFilterInputDate,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-pro";

export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const REGEX = {
  EMAIL_VALIDATION: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
  PASSWORD_VALIDATION:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
};

export const LEGAL_KEYS = {
  PRIVACY_POLICY: "privacy-policy",
  TERMS_AND_CONDITIONS: "terms-and-conditions",
  MEMBERSHIP_BENEFITS: "membership-benifits",
};

export const MUI_DATAGRID_KEY =
  "2a1203426ca4412164fd0b503afd0b83T1JERVI6MzkyOTgsRVhQSVJZPTE2NzgzODczMjgwMDAsS0VZVkVSU0lPTj0x";

export const HTTP_STATUSES = {
  OK: 200,
  NO_CONTENT: 204,
};

export const PAGINATION = {
  SERVER: "server",
  CLIENT: "client",
};

export const FILTER = {
  AND_SEPARATOR: ";",
  OR_SEPARATOR: ",",
  ASSIGNMENT_SEPARATOR: "=",

  AND_FILTER: "and",
  OR_FILTER: "or",
  EQUALS_OPERATOR: "equals",
  IS_OPERATOR: "is",
  IS_ANY_OF: "isAnyOf",
  RANGE_OPERATOR: "range",
  STARTS_WITH_OPERATOR: "startsWith",
  ENDS_WITH_OPERATOR: "endsWith",
  CONTAINS_OPERATOR: "contains",
  ON_OPERATOR: "on",
  ON_OR_AFTER_OPERATOR: "onOrAfter",
  ON_OR_BEFORE_OPERATOR: "onOrBefore",
};

export const OPERATOR_LIST = [
  FILTER.EQUALS_OPERATOR,
  FILTER.IS_OPERATOR,
  FILTER.IS_ANY_OF,
  FILTER.RANGE_OPERATOR,
  FILTER.STARTS_WITH_OPERATOR,
  FILTER.ENDS_WITH_OPERATOR,
  FILTER.CONTAINS_OPERATOR,
  FILTER.ON_OR_AFTER_OPERATOR,
  FILTER.ON_OR_BEFORE_OPERATOR,
  FILTER.ON_OPERATOR,
];

export const FILTER_OPERATORS = {
  DATE: [
    {
      label: FILTER.ON_OPERATOR,
      value: FILTER.ON_OPERATOR,
      InputComponent: GridFilterInputDate,
      InputComponentProps: { type: "date" },
    },
    ...getGridDateOperators().filter(
      ({ value }) =>
        value !== FILTER.IS_OPERATOR && OPERATOR_LIST.includes(value)
    ),
  ],
  STRING: getGridStringOperators().filter(
    ({ value }) => OPERATOR_LIST.includes(value) && value !== FILTER.IS_ANY_OF
  ),
  SINGLE_SELECT: getGridSingleSelectOperators().filter(({ value }) =>
    OPERATOR_LIST.includes(value)
  ),
};

export const ORDER_STATUS_DICT = {
  CREATED: "Created",
  COMPLETED: "Completed",
  ASSIGNED: "Assigned",
  PICKED: "Picked",
  ON_THE_WAY: "OnTheWay",
  DELIVERED: "Delivered",
  CANCELLED: "Cancelled",
};

export const ORDER_STATUS_COLOR_MAP = {
  [ORDER_STATUS_DICT.CREATED]: "primary",
  [ORDER_STATUS_DICT.COMPLETED]: "info",
  [ORDER_STATUS_DICT.ASSIGNED]: "secondary",
  [ORDER_STATUS_DICT.PICKED]: "warning",
  [ORDER_STATUS_DICT.ON_THE_WAY]: "info",
  [ORDER_STATUS_DICT.DELIVERED]: "success",
  [ORDER_STATUS_DICT.CANCELLED]: "error",
};

export const INGREDIENT_GROUP_TYPE = {
  SINGLE_SELECT: 1,
  SINGLE_COLUMN: 2,
  LEFT_RIGHT_FULL: 3,
};

export const INGREDIENT_GROUP_TYPE_DICT = {
  [INGREDIENT_GROUP_TYPE.SINGLE_SELECT]: "Single Select",
  [INGREDIENT_GROUP_TYPE.SINGLE_COLUMN]: "Single Column",
  [INGREDIENT_GROUP_TYPE.LEFT_RIGHT_FULL]: "Left Right Full",
};

export const MENU_CATEGORY_TYPE = {
  ENTREE: "Entree",
  DESSERT: "Dessert",
  SIDE: "Side",
};

export const MENU_CATEGORY_TYPE_ID = {
  [MENU_CATEGORY_TYPE.ENTREE]: 0,
  [MENU_CATEGORY_TYPE.DESSERT]: 1,
  [MENU_CATEGORY_TYPE.SIDE]: 2,
};

export const ORDER_CUT_OFF_CART_MESSAGE =
  "ordercutofftime will be auto-replaced with the Order Cut-Off Time";

export const ORDER_LOCATION_CUT_OFF_MESSAGE =
  "orderlocationcutoff will be auto-replaced with the Order Location Change Cut-Off Time";
