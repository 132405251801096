import { apiOptions, apiRequest } from "../utils";

export const accountsService = {
  getAll: async ({ params, filters, cancelToken, sort }) => {
    const options = await apiOptions({
      url: "accounts",
      method: "get",
      cancelToken,
      params,
      filters,
      sort,
    });

    return apiRequest(options);
  },

  get: async (accountId) => {
    const options = await apiOptions({
      url: `accounts/${accountId}`,
      method: "get",
    });

    return apiRequest(options);
  },

  delete: async (accountId) => {
    const options = await apiOptions({
      url: `accounts/${accountId}`,
      method: "delete",
    });

    return apiRequest(options);
  },

  create: async (data) => {
    const options = await apiOptions({
      url: "accounts",
      method: "post",
      data,
    });
    return apiRequest(options);
  },

  update: async (accountId, data) => {
    const options = await apiOptions({
      url: `accounts/${accountId}`,
      method: "patch",
      data,
    });
    return apiRequest(options);
  },
};
