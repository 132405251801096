import { apiOptions, apiRequest } from "../utils";

export const sessionService = {
  loginAdmin: async (data) => {
    const options = await apiOptions({
      url: "session/adminlogin",
      method: "post",
      data: data,
      noToken: true,
    });

    return apiRequest(options);
  },
};
