import { apiOptions, apiRequest } from "../utils";

export const accountDomainsService = {
  getAll: async () => {
    const options = await apiOptions({
      url: "accountdomains",
      method: "get",
    });

    return apiRequest(options);
  },

  getByAccountId: async (accountId) => {
    const options = await apiOptions({
      url: `accountdomains/account/${accountId}`,
      method: "get",
    });

    return apiRequest(options);
  },

  replace: async (accountId, data) => {
    const options = await apiOptions({
      url: `accountdomains/account/${accountId}`,
      method: "put",
      data: data,
    });
    return apiRequest(options);
  },
};
