import React, { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "./components/AuthGuard";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import GuestGuard from "./components/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import MainLayout from "./components/MainLayout";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const TermsAndCondition = Loadable(
  lazy(() => import("./pages/TermsAndCondition"))
);
const PrivacyPolicy = Loadable(lazy(() => import("./pages/PrivacyPolicy")));

const Login = Loadable(lazy(() => import("./pages/authentication/Login")));

const Account = Loadable(lazy(() => import("./pages/dashboard/Account")));
const DashBoards = Loadable(
  lazy(() => import("./components/dashboard/DashboardPage"))
);
const ItemCreate = Loadable(lazy(() => import("./pages/dashboard/ItemCreate")));
const ItemGroupCreate = Loadable(
  lazy(() => import("./pages/dashboard/ItemGroup"))
);
const ItemEdit = Loadable(lazy(() => import("./pages/dashboard/ItemEdit")));
const ItemList = Loadable(lazy(() => import("./pages/dashboard/ItemList")));
const UserList = Loadable(lazy(() => import("./pages/dashboard/User")));
const UserCreateForm = Loadable(
  lazy(() => import("./pages/dashboard/UserCreate"))
);
const UserEditForm = Loadable(lazy(() => import("./pages/dashboard/UserEdit")));
const StaffList = Loadable(lazy(() => import("./pages/dashboard/Staff")));

const StaffCreate = Loadable(
  lazy(() => import("./pages/dashboard/StaffCreate"))
);
const StaffEdit = Loadable(lazy(() => import("./pages/dashboard/StaffEdit")));
const DeliveryVansList = Loadable(
  lazy(() => import("./pages/dashboard/DeliveryVans"))
);
const DeliveryVansCreate = Loadable(
  lazy(() => import("./pages/dashboard/DeliveryVansCreate"))
);
const DeliveryVansEdit = Loadable(
  lazy(() => import("./pages/dashboard/DeliveryVansEdit"))
);

const Deliveries = Loadable(lazy(() => import("./pages/dashboard/Deliveries")));

const OrderDetails = Loadable(
  lazy(() => import("./pages/dashboard/OrderDetails"))
);
const OrderEdit = Loadable(lazy(() => import("./pages/dashboard/OrderEdit")));
const OrderList = Loadable(lazy(() => import("./pages/dashboard/OrderList")));
const RestaurantCreate = Loadable(
  lazy(() => import("./pages/dashboard/RestaurantCreate"))
);
const Restaurant = Loadable(lazy(() => import("./pages/dashboard/Restaurant")));
const RestaurantEdit = Loadable(
  lazy(() => import("./pages/dashboard/RestaurantEdit"))
);
const IngredientGroupList = Loadable(
  lazy(() => import("./pages/dashboard/IngredientGroupList"))
);
const IngredientGroupCreate = Loadable(
  lazy(() => import("./pages/dashboard/IngredientGroupCreate"))
);
const IngredientGroupEdit = Loadable(
  lazy(() => import("./pages/dashboard/IngredientGroupEdit"))
);
const AccountCreate = Loadable(
  lazy(() => import("./pages/dashboard/AccountCreate"))
);
const AccountEdit = Loadable(
  lazy(() => import("./pages/dashboard/AccountEdit"))
);
const AccountDomain = Loadable(
  lazy(() => import("./pages/dashboard/AccountDomain"))
);
const PolicyUpdate = Loadable(
  lazy(() => import("./pages/dashboard/PrivacyPolicy"))
);
const UpdateTerms = Loadable(
  lazy(() => import("./pages/dashboard/TermsConditions"))
);
const MembershipDash = Loadable(
  lazy(() => import("./pages/dashboard/MembershipDashboard"))
);
const MembershipBenefits = Loadable(
  lazy(() => import("./pages/MembershipBenefits"))
);

const AccountType = Loadable(
  lazy(() => import("./pages/dashboard/AccountType"))
);
const AccountTypeCreate = Loadable(
  lazy(() => import("./pages/dashboard/AccountTypeCreate"))
);
const AccountTypeEdit = Loadable(
  lazy(() => import("./pages/dashboard/AccountTypeEdit"))
);
const Roles = Loadable(lazy(() => import("./pages/dashboard/Roles")));

const IngredientList = Loadable(
  lazy(() => import("./pages/dashboard/IngredientList"))
);
const IngredientCreate = Loadable(
  lazy(() => import("./pages/dashboard/IngredientCreate"))
);
const IngredientEdit = Loadable(
  lazy(() => import("./pages/dashboard/IngredientEdit"))
);

const Community = Loadable(lazy(() => import("./pages/dashboard/Community")));
const CommunityCreate = Loadable(
  lazy(() => import("./pages/dashboard/CommunityCreate"))
);
const CommunityEdit = Loadable(
  lazy(() => import("./pages/dashboard/CommunityEdit"))
);

const DocumentDetail = Loadable(
  lazy(() => import("./pages/dashboard/DocumentsList"))
);

const MenuList = Loadable(lazy(() => import("./pages/dashboard/MenuList")));
const MenuCreate = Loadable(lazy(() => import("./pages/dashboard/MenuCreate")));
const MenuEdit = Loadable(lazy(() => import("./pages/dashboard/MenuEdit")));

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import("./pages/AuthorizationRequired"))
);
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

const routes = [
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "login-unguarded",
        element: <Login />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DashBoards />,
      },
      {
        path: "account",
        children: [
          {
            path: "",
            element: <Account />,
          },
          {
            path: "new",
            element: <AccountCreate />,
          },
          {
            path: ":accountId/edit",
            element: <AccountEdit />,
          },
          {
            path: ":accountId/domain",
            element: <AccountDomain />,
          },
        ],
      },
      {
        path: "accounttype",
        children: [
          {
            path: "",
            element: <AccountType />,
          },
          {
            path: "new",
            element: <AccountTypeCreate />,
          },
          {
            path: ":accounttypeId/edit",
            element: <AccountTypeEdit />,
          },
          {
            path: ":accountId/domain",
            element: <AccountDomain />,
          },
        ],
      },
      {
        path: "role",
        children: [
          {
            path: "",
            element: <Roles />,
          },
        ],
      },
      {
        path: "Item",
        children: [
          {
            path: "",
            element: <ItemList />,
          },
          {
            path: ":ItemId/edit",
            element: <ItemEdit />,
          },
          {
            path: "new",
            element: <ItemCreate />,
          },
          {
            path: ":ItemId/addgroup",
            element: <ItemGroupCreate />,
          },
        ],
      },
      {
        path: "menu",
        children: [
          {
            path: "",
            element: <MenuList />,
          },
          {
            path: "new",
            element: <MenuCreate />,
          },
          {
            path: ":menuId/edit",
            element: <MenuEdit />,
          },
        ],
      },
      {
        path: "user",
        children: [
          {
            path: "",
            element: <UserList />,
          },
          {
            path: "new",
            element: <UserCreateForm />,
          },
          {
            path: ":userId/edit",
            element: <UserEditForm />,
          },
        ],
      },
      {
        path: "privacy-policy",
        children: [
          {
            path: "",
            element: <PolicyUpdate />,
          },
        ],
      },
      {
        path: "terms-and-condition",
        children: [
          {
            path: "",
            element: <UpdateTerms />,
          },
        ],
      },
      {
        path: "membership-benefits",
        children: [
          {
            path: "",
            element: <MembershipDash />,
          },
        ],
      },
      {
        path: "staff",
        children: [
          {
            path: "",
            element: <StaffList />,
          },
          {
            path: ":staffId/edit",
            element: <StaffEdit />,
          },
          {
            path: "new",
            element: <StaffCreate />,
          },
        ],
      },
      {
        path: "delivery-vans",
        children: [
          {
            path: "",
            element: <DeliveryVansList />,
          },
          {
            path: "new",
            element: <DeliveryVansCreate />,
          },
          {
            path: ":deliveryvansId/edit",
            element: <DeliveryVansEdit />,
          },
        ],
      },
      {
        path: "deliveries",
        children: [
          {
            path: "",
            element: <Deliveries />,
          },
        ],
      },
      {
        path: "orders",
        children: [
          {
            path: "",
            element: <OrderList />,
          },
          {
            path: ":orderId",
            element: <OrderDetails />,
          },
          {
            path: ":orderId/edit",
            element: <OrderEdit />,
          },
        ],
      },
      {
        path: "restaurant",
        children: [
          {
            path: "",
            element: <Restaurant />,
          },
          {
            path: "new",
            element: <RestaurantCreate />,
          },
          {
            path: ":productId/edit",
            element: <RestaurantEdit />,
          },
        ],
      },
      {
        path: "ingredient",
        children: [
          {
            path: "",
            element: <IngredientList />,
          },
          {
            path: "new",
            element: <IngredientCreate />,
          },
          {
            path: ":ingredientId/edit",
            element: <IngredientEdit />,
          },
        ],
      },
      {
        path: "ingredient-group",
        children: [
          {
            path: "",
            element: <IngredientGroupList />,
          },
          {
            path: "new",
            element: <IngredientGroupCreate />,
          },
          {
            path: ":ingredientgroupId/edit",
            element: <IngredientGroupEdit />,
          },
        ],
      },
      {
        path: "communities",
        children: [
          {
            path: "",
            element: <Community />,
          },
          {
            path: "new",
            element: <CommunityCreate />,
          },
          {
            path: ":communityId/edit",
            element: <CommunityEdit />,
          },
        ],
      },
      {
        path: "Documents",
        children: [
          {
            path: "",
            element: <DocumentDetail />,
          },
        ],
      },
    ],
  },
  {
    path: "/",
    element: <Navigate to="/dashboard" />,
  },
  {
    path: "*",
    element: <MainLayout />,
    children: [
      {
        path: "terms-and-condition",
        element: <TermsAndCondition />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "membership-benefits/:membershipId",
        element: <MembershipBenefits />,
      },
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
