import { apiOptions, apiRequest } from "../utils";

export const legalService = {
  getAll: async ({ params, cancelToken }) => {
    const options = await apiOptions({
      url: "cms",
      method: "get",
      cancelToken,
      params,
      noToken: true,
    });

    return apiRequest(options);
  },

  replace: async (key, data) => {
    const options = await apiOptions({
      url: `cms/${key}`,
      method: "put",
      data,
    });
    return apiRequest(options);
  },

  update: async (key, data) => {
    const options = await apiOptions({
      url: `cms/${key}`,
      method: "patch",
      data,
    });
    return apiRequest(options);
  },
};
