import { apiOptions, apiRequest } from "../utils";

const PREFIX_PATH = "menucategory";

export const menusCategoryService = {
  getAll: async ({ params, filters, cancelToken, sort }) => {
    const options = await apiOptions({
      url: PREFIX_PATH,
      method: "get",
      cancelToken,
      params,
      filters,
      sort,
    });

    return apiRequest(options);
  },
  get: async (id) => {
    const options = await apiOptions({
      url: `${PREFIX_PATH}/${id}`,
      method: "get",
    });

    return apiRequest(options);
  },
  create: async (data) => {
    const options = await apiOptions({
      url: PREFIX_PATH,
      method: "post",
      data,
    });

    return apiRequest(options);
  },
  update: async (menuId, data) => {
    const options = await apiOptions({
      url: `${PREFIX_PATH}/${menuId}`,
      method: "put",
      data,
    });

    return apiRequest(options);
  },
  getMenuItems: async ({ params, cancelToken }) => {
    const options = await apiOptions({
      url: `${PREFIX_PATH}/menuitems`,
      method: "get",
      cancelToken,
      params,
    });

    return apiRequest(options);
  },
};
