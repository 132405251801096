import {
  createAndFilter,
  createOrFilter,
  setContainsOperation,
  setStartsWithOperation,
  setEndsWithOperation,
  setEqualsOperation,
  setGreaterThanEqualOperation,
  setLessThanEqualOperation,
  setRangeOperation,
  removeOperationByField,
} from "./createQuery";
import { FILTER } from "../constants";

const create = (type) => {
  if (type === FILTER.OR_FILTER) {
    return createOrFilter();
  }
  return createAndFilter();
};

export const createFilter = (type) => {
  let filter = create(type);

  const reset = () => {
    return create(type);
  };

  const contains = (fieldValuePair) => {
    filter = setContainsOperation({ ...filter }, fieldValuePair);
    return filter;
  };

  const startsWith = (fieldValuePair) => {
    filter = setStartsWithOperation({ ...filter }, fieldValuePair);
    return filter;
  };

  const endsWith = (fieldValuePair) => {
    filter = setEndsWithOperation({ ...filter }, fieldValuePair);
    return filter;
  };

  const equals = (fieldValuePair) => {
    filter = setEqualsOperation({ ...filter }, fieldValuePair);
    return filter;
  };

  const greaterThanOrEqual = (fieldValuePair) => {
    filter = setGreaterThanEqualOperation({ ...filter }, fieldValuePair);
    return filter;
  };

  const lessThanOrEqual = (fieldValuePair) => {
    filter = setLessThanEqualOperation({ ...filter }, fieldValuePair);
    return filter;
  };

  const inRange = (fieldValuePair) => {
    filter = setRangeOperation({ ...filter }, fieldValuePair);
    return filter;
  };

  const removeField = (field) => {
    filter = removeOperationByField({ ...filter }, field);
    return filter;
  };

  const getFilter = () => filter;

  return {
    contains,
    startsWith,
    endsWith,
    equals,
    greaterThanOrEqual,
    lessThanOrEqual,
    inRange,
    removeField,
    reset,
    getFilter,
  };
};
