import { apiOptions, apiRequest } from "../utils";

export const folderService = {
  getAll: async ({ params, cancelToken }) => {
    const options = await apiOptions({
      url: "folder",
      method: "get",
      cancelToken,
      params,
    });

    return apiRequest(options);
  },

  get: async (id) => {
    const options = await apiOptions({
      url: `folder/${id}`,
      method: "get",
    });

    return apiRequest(options);
  },

  delete: async (id) => {
    const options = await apiOptions({
      url: `folder/${id}`,
      method: "delete",
    });

    return apiRequest(options);
  },

  create: async (data) => {
    const options = await apiOptions({
      url: `folder`,
      method: "post",
      data,
    });
    return apiRequest(options);
  },

  update: async (id, data) => {
    const options = await apiOptions({
      url: `folder/${id}`,
      method: "patch",
      data,
    });
    return apiRequest(options);
  },
};
