import { BASE_URL } from "../baseUrl";
import { createQueryString } from "./createQuery";

export const apiOptions = async ({
  params = {},
  url = "",
  method = "get",
  cancelToken,
  data,
  headers,
  filters,
  responseType,
  contentType,
  noToken,
  sort,
} = {}) => {
  if (filters) {
    params = { ...params, query: createQueryString(filters) };
  }

  if (sort) {
    params = {
      ...params,
      orderBy: sort[0]?.field,
      descending: sort[0]?.sort === "desc",
    };
  }

  const options = {
    method: method.toLowerCase(),
    url: `${BASE_URL}${url}`,
    headers: {
      "Content-Type": contentType ?? "application/json",
    },
    params,
    responseType,
  };

  if (cancelToken) {
    options.cancelToken = cancelToken;
  }

  if (headers) {
    options.headers = { ...options.headers, ...headers };
  }

  if (!noToken) {
    const userToken = JSON.parse(localStorage.getItem("Login")).token;
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  switch (options.method) {
    case "post":
    case "patch":
    case "put":
      return {
        ...options,
        data,
      };
    case "delete":
    case "get":
    default:
      return options;
  }
};
